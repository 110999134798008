import React from 'react'
import { graphql } from 'gatsby'
import {
  Container,
  Alert,
} from 'reactstrap'
import Header from '../components/pages/common/header'
import Layout from '../components/layout'
import Page from '../components/page'
import Helmet from 'react-helmet'

const ShopPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Shop | Whickham Thorns Outdoor Activity Centre</title>
      </Helmet>
      <Header
        headerImage={data.headerImage}
        title="Shop"
        height="20rem"
      />
      <Page>
        <Container>
          <Alert color="danger" className="mb-4">
            <strong>Gateshead Scout Shop is currently closed until further notice. Please check back later for more information on when we are reopening.</strong>
          </Alert>

          <p>
            Gateshead Scout Shop is based within Whickham Thorns Outdoor Activity Centre, and supplies everything you need for your scouting adventure. We stock a full range of uniforms, badges, books and resources etc. All profits go directly in to scouts in Gateshead.
          </p>

          <p>
            Orders can be taken by email, phone or voicemail and collected in store.
          </p>

          {/* <h4>Opening Hours</h4>

          <p>Saturday 11am – 1pm</p>

          <p>Closed for Christmas and Summer Holidays</p> */}

          <h4>Contact Us</h4>

          <p>Phone: 0191 461 1643</p>
          <p>Email: <a href="mailto:shop@gatesheadscouts.org.uk">shop@gatesheadscouts.org.uk</a></p>

          <h5>Address</h5>

          <address>
            <p>
              <strong>Gateshead Scout Shop</strong><br />
              Whickham Thorns Outdoor Activity Centre,<br />
              Market lane,<br />
              Gateshead<br />
              NE11 9NX
            </p>
          </address>
        </Container>
      </Page>
    </Layout>
  )
}

export const query = graphql`
  query ShopQuery {
    headerImage: file(relativePath: { regex: "/shop/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
      }
    }
  }
`

export default ShopPage
